import { styled, typography } from '@pulse-web-ui/theme';

export const DocumentItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  cursor: pointer;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Label = styled.h4`
  ${typography.regular12};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin: 0;
`;

export const Value = styled.p`
  ${typography.regular16};
  margin: 0;
  line-height: 24px;
  flex: 1;
`;
