import { type ReactNode } from 'react';

import { StatusWrapper } from './status.styles';
import type { StatusType } from './status.types';

interface Props {
  children: ReactNode;
  type: StatusType;
}

export const Status = ({ children, type }: Props) => {
  return <StatusWrapper $type={type}>{children}</StatusWrapper>;
};
