import { styled, css, media } from '@pulse-web-ui/theme';

type JustifyContentType =
  | 'start'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

export const FormControlContainer = styled.div<{
  $margin?: string;
}>`
  width: 100%;
  margin-bottom: 12px;

  ${({ $margin }) =>
    $margin &&
    css`
      margin: ${$margin};
    `}
`;

export const TabsContainer = styled.div`
  margin-bottom: 8px;
`;

export const StepsListContainer = styled.div`
  width: 100%;
  max-width: 768px;
  padding: 0 24px;
  box-sizing: border-box;
  margin-bottom: 24px;

  ${media.desktop} {
    padding: 0;
  }
`;

export const ColumnContainer = styled.div<{ $gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => $gap ?? 8}px;
`;

export const ContainerWithPadding = styled.div`
  width: 100%;
  max-width: 768px;
  padding: 0px 16px;
  box-sizing: border-box;
`;

export const RowContainer = styled.div<{
  $justify?: JustifyContentType;
  $gap?: number;
  $marginBottom?: number;
}>`
  display: flex;
  justify-content: ${({ $justify }) => $justify ?? 'start'};
  align-items: center;
  gap: ${({ $gap }) => $gap ?? 0}px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? 24}px;
  width: 100%;
`;
