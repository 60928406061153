import { styled, typography } from '@pulse-web-ui/theme';

import { type StatusType } from './status.types';

export const StatusWrapper = styled.div<{ $type: StatusType }>`
  ${typography.regular12};
  display: inline-block;
  background-color: ${({ theme, $type }) => {
    if ($type === 'error') {
      return theme.colors.accent.error.clarified;
    } else if ($type === 'warning') {
      return theme.colors.surface.warning['success-light'];
    } else if ($type === 'success') {
      return theme.colors.surface.success['success-light'];
    } else {
      return theme.colors.surface.success['success-light'];
    }
  }};
  color: ${({ theme, $type }) => {
    if ($type === 'error') {
      return theme.colors.text.error;
    } else if ($type === 'warning') {
      return theme.colors.text.warning;
    } else if ($type === 'success') {
      return theme.colors.text.success;
    } else {
      return theme.colors.text.success;
    }
  }};
  border-radius: 16px;
  padding: 0 8px;
`;
