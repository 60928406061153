import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Exit } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';
import { useTranslation } from 'react-i18next';

import { addTestAttribute, ERROR, IconButton } from '@src/shared';
import { UserContext } from '@src/app/contexts/user-context';
import { useLogout } from '../api';

interface SubmitLogoutProps {
  onLogout: () => void;
  className?: string;
}

export const SubmitLogout = ({ onLogout, className }: SubmitLogoutProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleLogout } = useContext(UserContext);
  const { refetch, error } = useLogout();

  const handleLogoutClick = () => {
    refetch().then((data) => {
      if (data.status === 'success') {
        handleLogout();
      }
      onLogout();
    });
  };

  useEffect(() => {
    if (error) {
      navigate(ERROR);
    }
  }, [error]);

  return (
    <div
      className={className}
      onClick={handleLogoutClick}
      {...addTestAttribute('logout-button')}
    >
      <IconButton>
        <Exit width={18} color={theme.colors.icon.primary} />
      </IconButton>
      <span>{t('COMMON:navigation.logout')}</span>
    </div>
  );
};
