import React, { memo, useState } from 'react';
import { ArrowDown, ArrowUp, SystemCheck } from '@pulse-web-ui/icons';

import {
  Label,
  StyledSelect,
  SelectArrow,
  SelectList,
  SelectOption,
  SelectPopover,
} from './select.styles';
import type { Option, SelectProps } from './select.types';
import { addTestAttribute } from '@shared/index';
import { useTheme } from '@pulse-web-ui/theme';

export const Select = memo(
  ({
    options,
    value,
    onChange,
    onBlur,
    ariaLabel,
    disabled,
    label,
    error,
    testId = 'select',
  }: SelectProps) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value);
    const [selectedOptionLabel, setSelectedOptionLabel] = useState(
      options?.find((option) => option.value === value)?.label
    );

    const handleSelectChange = (option: Option) => {
      setSelectedValue(option.value);
      setSelectedOptionLabel(option.label);
      onChange && onChange(option.value);
    };

    return (
      <StyledSelect
        aria-labelledby={ariaLabel || ''}
        onClick={() => !disabled && setIsOpen(!isOpen)}
        onBlur={(e) => {
          setIsOpen(false);
          onBlur && onBlur(e);
        }}
        $isError={error}
        tabIndex={1}
        disabled={disabled}
        {...addTestAttribute(`${testId}.selectInput`)}
      >
        <Label $isError={error} $disabled={disabled} $value={selectedValue}>
          {label}
        </Label>
        {selectedOptionLabel}
        <SelectArrow $disabled={disabled}>
          {isOpen ? (
            <ArrowUp color={theme.colors.icon.primary} />
          ) : (
            <ArrowDown color={theme.colors.icon.primary} />
          )}
        </SelectArrow>
        {isOpen && (
          <SelectPopover>
            <SelectList>
              {options?.map((option) => (
                <SelectOption
                  key={`${option.label}_${option.value}`}
                  onClick={() => {
                    handleSelectChange(option);
                  }}
                  {...addTestAttribute(`${testId}.selectOption`)}
                >
                  <span>{option.label}</span>
                  {value && option.value === value && (
                    <SystemCheck width={20} />
                  )}
                </SelectOption>
              ))}
            </SelectList>
          </SelectPopover>
        )}
      </StyledSelect>
    );
  }
);
