import { styled } from '@pulse-web-ui/theme';

export const Item = styled.div<{ $signed?: boolean }>`
  display: flex;
  gap: 8px;
  padding: 8px 12px;
  background-color: ${({ theme, $signed }) =>
    $signed ? theme.colors.surface.background : 'transparent'};
  border: ${({ theme, $signed }) =>
    `1px solid ${
      $signed ? theme.colors.surface.background : theme?.colors?.neutral['30']
    }`};
  border-radius: calc(${({ theme }) => theme.common.radius} * 2);
  align-items: center;
`;

export const NameContainer = styled.p`
  margin: 0;
  flex-basis: fit-content;
  color: ${({ theme }) => theme.colors.text.interactive};
  cursor: pointer;
  line-height: 24px;
`;
