import { styled, typography } from '@pulse-web-ui/theme';

export const WidgetHeader = styled.h2<{ $marginBottom?: number }>`
  ${typography.medium20};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? 0}px;
`;

export const WidgetSubHeaderText = styled.h3<{
  $center?: boolean;
  $marginBottom?: number;
}>`
  ${typography.regular16}
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? 16}px;
  text-align: ${({ $center }) => ($center ? 'center' : 'inherit')};
`;

export const PageHeader = styled.h1<{
  $marginBottom?: number;
  $padding?: [number, number];
}>`
  ${typography.medium24};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? 0}px;
  padding: ${({ $padding }) => $padding?.join('px ') ?? '0 0'}px;
`;

export const FormControlHeader = styled.h3`
  ${typography.medium18};
  font-weight: 600;
  margin-bottom: 12px;
`;
